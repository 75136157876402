/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/**
 * Set `margin-top` to ease readability
 */
h1, h2, h3, h4 {
    margin-top: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    padding-left: 10px;
    margin-left: $spacing-unit;
}

li {
    padding-top: 2px;
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

hr {
  height: 2px;
  margin: 10px 0;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:    -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:     -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  border: 0;
}

/**
 * Links
 */
a {
    color: $link-color;
    text-decoration: none;
    border-bottom: 1px dotted $link-color-line;

    &:visited {
        color: darken($link-color, 15%);
    }

    &:hover {
        color: $link-color-hover;
    }
}

h1 > a {
  color: $text-color;
}
h1 > a:visited {
  color: $text-color;
}

/**
 * Blockquotes
 */
blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    width: 500px;
    margin: 0.25em 0;
    padding: 0.35em 45px;
    line-height: 1.45;
    position: relative;
    color: $grey-color;
}
blockquote:before {
    display: block;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: 0px;
    top: -20px;
    color: $grey-color;
}
blockquote > :last-child {
    margin-bottom: 0;
}
blockquote cite {
    color: $grey-color-light;
    font-size: 14px;
    font-style: normal;
    display: block;
    margin-top: 5px;
}
blockquote cite:before {
    content: "\2014 \2009";
}

/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    background-color: #f8f8f8;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

.post-comments {
    font-variant: small-caps;
}
