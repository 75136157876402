@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.845;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #f0f0f0;
$page-color: 	   #fdfdfd;

$link-color:       #1977ca;
$link-color-line:  #cbd2d6;
$link-color-hover: #a5b2b9;

$grey-color:       #626262;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$meta-color:     #F1F1F1;
$meta-stripe:    #1977ca;

// Width of the content area
$on-palm:          600px;
$on-laptop:        950px;

$content-width:    1050px;


@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "svg-icons"
;

/* Screen size gets smaller? Remove image: */
@media screen and (max-width: 1020px) {

  .post-thumbnail {
  	display: none;
  }
  .page-thumbnail {
    width: 100%;
  }
  .post-data {
    width: 100%;
  }
  div.page-content > div.wrapper {
    width: auto;
  }
  header > div.wrapper {
    width: auto;
  }
  .footer-wrapper {
    width: 100%;
  }
  .pagination {
    width: 100%;
  }
}
