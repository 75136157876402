/**
 * Site header
 */
.site-header {
    border-top: 4px solid $grey-color-dark;
    // Positioning context for the mobile navigation icon
    position: relative;
}

/**
 * Fix header
 */
 header > div.wrapper {
    width: $content-width - $spacing-unit - $spacing-unit;
    background-color: $page-color;
    padding-top: 5px;
}
div.page-content > div.wrapper {
    width: $content-width - $spacing-unit - $spacing-unit;
    background-color: $page-color;
    padding: $spacing-unit;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    border: none;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    padding: $spacing-unit 0;
}

.footer-wrapper {
    width: $content-width - 60px;
    text-align: center;
    padding-bottom: 160px;
}

.footer-wrapper-content {
    margin: 0 auto;
    display: inline-block;
    > a {
      border-bottom: none;
    }
}



/**
 * Page content
 */
.page-content {
    padding: 0px;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.title-link {
    border-bottom: 0px;
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.left {
    float: left;
	margin-right:auto;
}

.right {
    float: right;
	margin-left:auto;
}

.clear {
	clear: both;
}

.post-content {
    margin-bottom: $spacing-unit;

    h1 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h2 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 23px;
        }
    }

    h3 {
        font-size: 23px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    h4 {
        font-size: 18px;

        @include media-query($on-laptop) {
            font-size: 16px;
        }
    }

    .post-title {
        font-size: 42px;
        letter-spacing: -1px;
        line-height: 1;

        @include media-query($on-laptop) {
            font-size: 36px;
        }
    }

}




/* From WordPress */

.alignleft {
	float: right;
}

.alignright {
	float: right;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/**
 * Index post section:
 */

.post {
	display: block;
	overflow: auto;
    margin: auto;
    clear: both;
}

.post-thumbnail {
    align: top;
    float: left;
    width: 100px;
    padding: 10px $spacing-unit $spacing-unit 0px;
}

.page-thumbnail {
	align: top;
	padding: 10px $spacing-unit $spacing-unit 0px;
}

.post-thumbnail-img {
	border-radius: 5px;
    float: left;
	padding: 0px;
	margin: 0px;
 	height: 100px;
 	width: 100px;
}

.post-data {
    width: $content-width - $spacing-unit - $spacing-unit - 100px - $spacing-unit;
 	float: left;
}

.pagination-center {
    margin: 0 auto;
    display: inline-block;
}

.pagination {
    width: $content-width - $spacing-unit - $spacing-unit;
	padding: 0px 10px;
    text-align: center;
}

.pagination-item {
	padding-right: 5px;
	min-width: 15px;
	float: left;
	> a {
      border-bottom: none;
    }
}

.page-navigation {
	border-radius: 10px;
    border: 1px dotted $grey-color-light;
	padding: 10px $spacing-unit;
	margin-bottom: $spacing-unit;
}